/**
 * Created by loi.nguyen on 4/12/2017.
 */
// Note: This example requires that you consent to location sharing when
// prompted by your browser. If you see the error "The Geolocation service
// failed.", it means you probably did not give permission for the browser to
// locate you.

function initMap() {
    var company = {lat: 10.858903, lng: 106.725234};
    var map = new google.maps.Map(document.getElementById('map'), {
        scaleControl: true,
        center: company,
        zoom: 16
    });

    var infowindow = new google.maps.InfoWindow;

    var content = '<div class="iw-container">' +
        '<div class="iw-title">'+titleCompany+'</div>' +
        '<div class="iw-address">'+addressCompany+'</div>' +
        '</div>';

    infowindow.setContent(content);

    var marker = new google.maps.Marker({map: map, position: company, title:"Đăng Hồng Phát"});
    /*marker.addListener('click', function() {*/
        infowindow.open(map, marker);
    /*});*/
}


 /* 5 ways to customize the Google Maps infowindow
 * 2015 - en.marnoto.com
 * http://en.marnoto.com/2014/09/5-formas-de-personalizar-infowindow.html
*/