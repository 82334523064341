/**
 * Created by loi.nguyen on 3/31/2017.
 */
$(document).ready(function () {

    $('#banner').nivoSlider({
        pauseOnHover:false // Stop animation while hovering
});

    $('.slider-customer-of-us.owl-carousel').owlCarousel({
        loop: true,
        margin: 15,
        nav: true,
        navText: ["<i class='dhp-dropdown'>", "<i class='dhp-dropdown'>"],
        responsiveClass: true,
        responsive: {
            0: {
                items: 2,
                nav: false
            },
            540: {
                items: 4,
                nav: false
            },
            600: {
                items: 6,
                nav: false
            },
            1000: {
                items: 8,
                nav: true,
                loop: false
            }
        }
    });

    $('.slider-detail-product.owl-carousel').owlCarousel({
        items: 1,
        margin: 10,
        nav: true,
        navText: ["<i class='dhp-dropdown'>", "<i class='dhp-dropdown'>"],
        autoHeight: true
    });

    var w_carousel = ($('#carousel.flexslider').width()) / 3;

    $('#carousel').flexslider({
        animation: "slide",
        controlNav: false,
        animationLoop: false,
        slideshow: false,
        itemWidth: w_carousel,
        itemMargin: 10,
        asNavFor: '#slider'
    });

    $('#slider').flexslider({
        animation: "slide",
        controlNav: false,
        animationLoop: false,
        slideshow: false,
        sync: "#carousel",
        before: function () {
            var i = $("#slider .slides li.flex-active-slide").find('iframe')[0].contentWindow;
            i.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        }
    });

    $('.box-img-support-customer').css('height', getHieghtDiv('.backgroup-color-content-infor-support'));
});

$(window).resize(function () {
    $('.box-img-support-customer').css('height', getHieghtDiv('.backgroup-color-content-infor-support'));
});

function getHieghtDiv(iclass) {
    var height_div = $(iclass).outerHeight();
    return height_div;
}